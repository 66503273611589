import React, {useEffect, useState, useRef} from 'react';

import './profile.css';
import {ImageAssets, IconAssets} from '../../utils/ImageAssets';
import {message} from 'antd';

import {useNavigate, useLocation} from 'react-router-dom';
import { Rate } from 'antd';
import Speciality from '../../components/speciality/speciality.component';
import SpecialityBig from '../../components/specialityBig/specialityBig.component'
import Button from '../../components/button/button.component';
import Avatar from '../../components/avatar/avatar.component';
import NotUser from '../../assets/img/notUser.png';

import {getCoach} from '../../services/data.service'
import { createChat, createIAChat } from '../../services/chat.service';
import { createThread } from '../../services/ia.service';

import {editProfileImage} from '../../services/user.service';

import { Const } from '../../utils/variables';

import moment from 'moment';
import {useTranslation} from "react-i18next";

const Profile = () => {
    const [coach, setCoach] = useState(null);
    const [daysWork, setDaysWork] = useState('');
    const [workingHours, setWorkingHours] = useState([]);
    const actualDate = new Date();
    const [description, setDescription] = useState(null);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const {t} = useTranslation();

    // Se ejecuta al entrar a la vista
    useEffect(() => {
        getCoachProfile();
    }, [])

    const getCoachProfile = () => {
        getCoach(location.state.idCoach).then(res => {
            setCoach(res.data);
            daysOfWork(res.data.WorkingHours);
            calculateWorkHours(res.data.WorkingHours);
        }).catch(err => {
            //console.log('Error al recoger el coach', err)
        })
    }

    //Saca los dias que trabajas los ordena y los mete en orden en el string para poder pintarlo
  const daysOfWork = (daysWork) => {
    let listDaysWork = [];
    let listDaysWorkInOrder = [];
    let stringDaysWork = '';
    daysWork.map(res => {
      if (!listDaysWork.includes(res.weekday)) {
        listDaysWork.push(res.weekday);
      }
    })

    /*Guarda los dias que trabaja el usuario en un array para luego ordenarlo y poder pintarlo en orden*/
    listDaysWork.map(res => {
      switch(res) {
        case 'MO':
          listDaysWorkInOrder.push({id: 0, name: t('monday')})
          break;
        case 'TU':
          listDaysWorkInOrder.push({id: 1, name: t('tuesday')})
          break;
        case 'WE':
          listDaysWorkInOrder.push({id: 2, name: t('wednesday')})
          break;
        case 'TH':
          listDaysWorkInOrder.push({id: 3, name: t('thursday')})
          break;
        case 'FR':
          listDaysWorkInOrder.push({id: 4, name: t('friday')})
          break;
        case 'SA':
          listDaysWorkInOrder.push({id: 5, name: t('saturday')})
          break;
        case 'SU':
          listDaysWorkInOrder.push({id: 6, name: t('sunday')})
          break;
      }
    })

    /*Ordena el array en el orden que van los dias en la semana*/
    listDaysWorkInOrder = listDaysWorkInOrder.sort((a, b) => a.id - b.id);
    /*Monta el estrig que se va a mostrar con los dias que se trabaja*/
    listDaysWorkInOrder.map((res, index) => {
      if (listDaysWorkInOrder.length === 1) {
        stringDaysWork = stringDaysWork + res.name+'.';
      } else if (index === listDaysWorkInOrder.length - 1 && listDaysWorkInOrder.length > 1) {
        stringDaysWork = stringDaysWork + ' y '+res.name+'.';
      } else if (listDaysWorkInOrder.length > 1 && index === 0) {
        stringDaysWork = stringDaysWork + res.name;

      } else {
        stringDaysWork = stringDaysWork + ', '+res.name;
      }
    })

    setDaysWork(stringDaysWork);
  }

  /*Recoge las horas que trabaja el usuario en la semana y guarda en un array
  * las horas que trabaja en el dia actual*/
  const calculateWorkHours = (workingHours) => {
    let newWorkingHours = [];
    let actualWeeklyDay = moment(actualDate).format('dd').toUpperCase();
    workingHours.map(res => {
      if (res.weekday === actualWeeklyDay) {
        //console.log('WORKING HOURS', res);
        newWorkingHours.push(res);
      }
    })
    setWorkingHours(newWorkingHours);
  }

  /*Con el array que se ha hecho en la funcion anterior (El que contiene las horas que trabaja el coach en el dia actual)
  * Lo recorre y monta el string en el que muestra las horas que va a trabajar este coach en el dia actual*/
  const printWorkingHours = () => {
    let textWorkingHours = '';
    workingHours.map((res, index) => {
      if (workingHours.length === 1) {
        textWorkingHours = textWorkingHours + `${moment(res.start.replace('+02:00', '')).format('HH:mm')} a ${moment(res.end.replace('+02:00', '')).format('HH:mm')}`
      } else {
        if (index === 0) {
          textWorkingHours = textWorkingHours + `${moment(res.start.replace('+02:00', '')).format('HH:mm')} a ${moment(res.end.replace('+02:00', '')).format('HH:mm')}`
        } else {
          textWorkingHours = textWorkingHours + `, ${moment(res.start.replace('+02:00', '')).format('HH:mm')} a ${moment(res.end.replace('+02:00', '')).format('HH:mm')}`
        }
      }
    })
    return textWorkingHours;
  }

  const newChat = () => {
    if (coach.status == 'Offline' || coach.status == 'Busy') {
      alert('¡UPS! ' + t('coachNotAvailableText'))
    } else {
      if (window.confirm(t('newChatText'))) {
        createChat(location.state.idCoach).then(res => {
            let chat = res.data;
            navigate('/chat', {state: {idChat: chat.id, coach: coach, writer: true}})
        }).catch(err => {
            //console.log('No se ha podido crear el nuevo chat', err)
        })
      } else {
          //console.log('No iniciar nuevo chat')
      }
    } 
  }

  const newIaChat = () => {
    if (coach.status == 'Offline' || coach.status == 'Busy') {
      alert('¡UPS! ' + t('coachNotAvailableText'))
    } else {
      if (window.confirm(t('newChatText'))) {
        //Crea un nuevo hilo IA
        createThread().then(res => {
          navigate('/chati', {state: {isNew: true, thread_id: res.data.id, coach: coach, writer: true}})
          //Crea un chat o recoge el existente
          /*createIAChat(location.state.idCoach, res.data.id).then(newChat => {
              //Si el chat ya tiene el id del hilo navega a la vista mandado el id del hilo que contiene el chat
              //Si el chat es nuevo navega a la vista y envia el id del hilo que acabamos de crear
              if (newChat.data.thread_id != null) {
                navigate('/chati', {state: {idChat: newChat.data.id, thread_id: newChat.data.thread_id, coach: coach, writer: true}})
              } else {
                navigate('/chati', {state: {idChat: newChat.data.id, thread_id: res.data.id, coach: coach, writer: true}})
              }
            }).catch(err => {
                console.log('No se ha podido crear el nuevo chat', err)
            })*/
        }).catch(err => {
          console.log('Error creating thread', err)
        })
        
      } else {
          console.log('No iniciar nuevo chat')
      }
    } 
  }


    return (
        coach !== null &&
        <div className={'containerProfile'}>
            <p className='titleProfile'>{t('profile')}</p>
            <input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => console.log('')}/>
            <div className={'containerContentProfile'}>
                <div className={'containerImageProfile'}>
                    <div className={'containerImageProfile2'}>
                        <img className={'imageProfile'} src={coach.image ? `https://${Const.baseURL}/${coach.image}` : NotUser}/>
                    </div>
                    {coach.status == 'Online' ?
                        <div className={'buttonEditImageProfileOnline'}>
                        </div> : null
                    }
                    {coach.status == 'Offline' ?
                        <div className={'buttonEditImageProfileOffline'}>
                        </div>: null
                    }
                    {coach.status == 'Bussy' ?
                        <div className={'buttonEditImageProfileBussy'}>
                        </div>
                        : null
                    }
                </div>
                <div className='containerRateCoach'>
                    <p className='nameProfileCoach'>{coach.name + ' ' + coach.lastname}</p>
                    <p className={'titleRatingProfile'}>{coach.total_interactions} {t('ratings')}</p>
                    <Rate
                        className={'rateProfile'}
                        value={coach.rating}
                        disabled
                        allowHalf
                    />
                    {(coach.status === 'connect' || coach.status === 'Online') ? <p className='statusCoachProfile'>{t('available')}</p> : null}
                    {coach.status === 'Offline' ? <p className='statusCoachProfile'>{t('notAvailable')}</p> : null}
                    {coach.status === 'Busy' ? <p className='statusCoachProfile'>{t('busy')}</p> : null}
                </div>

                <div className='containerButton' onClick={() => {
                  if (coach.is_ia) {
                    newIaChat()
                  } else {
                    newChat()
                  }
                  }}>
                    <Button text={'Iniciar chat'}/>
                </div>
                
            </div>

            <div className='descriptionCoachProfile'>
                {coach.description}
            </div>

            <p className='daysWorkCoachProfile'>{daysWork}</p>
            <p className='daysWorkCoachProfile'>{printWorkingHours()}</p>

            <p className={'titleDataProfile'}>{t('specialities')}</p>
            <div className={'containerSpecialitiesProfile'}>
                {coach.Specialities.map(speciality => {
                    return (
                        <SpecialityBig title={speciality.name} description={speciality.description}/>
                    )
                })
                }
            </div>

            {/*<div className={'containerDataProfile'}>
                <p className={'titleDataProfile'}>Nombre y apellido</p>
                <p className={'nameProfile'}>{coach.name + ' '+ coach.lastname}</p>

                <p className={'titleDataProfile'}>Correo electrónico</p>
                <p className={'nameProfile'}>{coach.email}</p>

                

                <p className={'titleDataProfile'}>Descripción profesional</p>
                <textarea
                    className={'textareaProfile'}
                    placeholder={'Escriba aqui...'}
                    value={description === user.description ? user.description : description}
                    onChange={value => setDescription(value.target.value)}
                />

                <div className={'containerButtonProfile'}>
                    <Button
                        text={'Guardar'}
                        disabled={description === user.description}
                        press={() => changeDescription()}
                    />

                </div>
            </div>*/}

        </div>
    )
}

export default Profile;
