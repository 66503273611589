import React, {useEffect, useState} from 'react';
import './coaches.css'
import {useNavigate} from 'react-router-dom';

import { getCoaches } from '../../services/data.service';

import Searcher from "../../components/searcher/searcher.component";
import ChatItem from '../../components/chatItem/chatItem.component';
import {useTranslation} from "react-i18next";

const Coaches = () => {
    const [allCoaches, setAllCoaches] = useState([]);
    const [coaches, setCoaches] = useState([]);

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        //Eliminamos el id del chat que acabamos de crear
        localStorage.removeItem('idNewChat')
        
        getCoaches().then(res => {
            setCoaches(res.data.available_coaches);
            setAllCoaches(res.data.available_coaches);
        }).catch(err => {
            if (err.response.status == 469) {
                navigate('/rating', {state: {idChat: err.response.data.channelId, coach: err.response.data.coach}})
            }
            //console.log('Nose han podido recoger los coaches', err.response.data)
        })
    }, [])

    const filterCoaches = (value) => {
        if (value !== '') {
            let newCoaches = coaches.filter(coach => {
                let fullName = coach.name + ' ' + coach.lastname
                if (fullName.toLowerCase().startsWith(value.toLowerCase())) return coach;
            })
            setCoaches(newCoaches);
        }
        else {
            setCoaches(allCoaches);
        }
    }

    return (
        <div className={'containerCoaches'}>
            <p className='titleCoaches'>Coaches</p>

            <Searcher changeValue={value => filterCoaches(value)}/>
            <div className={'containerItemCoaches'}>
            {coaches.map(coach => {
                return (
                    <ChatItem
                        image={coach.image}
                        fullName={`${coach.name} ${coach.lastname}`}
                        status={coach.status}
                        timeConnect={coach.availability_text}
                        quantitySpecialities={coach.Specialities.length}
                        press={() => navigate('/profile', {state: {idCoach: coach.id}})}
                        timeLastMessage={''}
                    />
                )
            })
            }
            </div>

            
        </div>
    )
}

export default Coaches;