import React, {useRef} from 'react';

import './userNavBar.css';
import { ImageAssets } from '../../utils/ImageAssets';

import Avatar from '../avatar/avatar.component';

// Muestra el componente del los datos del coach en el navbar
const UserNavBar = ({name, email, image, status, press}) => {

    return (
        <div className={'containerUserNavBar'}>
            <div className={'containerImageProfile'}>
                <Avatar image={image} status={status} hiddenStatus/>
            </div>

            <div className={'containerTextUserNavBar'}>
                <p className={'nameUserNavBar'}>{name}</p>
                <p className={'emailUserNavBar'}>{email}</p>
            </div>
        </div>
    )
}

export default UserNavBar;
