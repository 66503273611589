import axios from 'axios';
import { Const } from '../utils/variables';

const axiosInstance = axios.create({baseURL: `https://${Const.baseURL}/api/`, responseType: 'json'});

/**
 * Iniciar sesion
 * */
export const login = (email, pass) => {
    const headers = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };
    return new Promise((resolve, reject) => {
        axiosInstance.post('login', {email: email, password: pass}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Cerrar sesion
 * */
export const logout = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('logout', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge el perfil del usuario
 * */
export const getProfile = () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('my_profile', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Resetea la password del usuario
 * */
export const resetPassword = (email) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
       // 'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.post(`remember_password`, {email:email}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * Recoge los eventos del calendario del usuario
 * */
export const getCalendar = (idUser) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`get_working_hours/${idUser}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
/**
 * Recoge los dias festivos
 * */
export const getHolidaysDays = (idUser) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`holidays`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Edita la imagen de perfil
 * */
export const editProfileImage = async (formData) => {
    const headers = {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        files: {
            file: formData
        }
    }


    return new Promise((resolve, reject) => {
        axiosInstance.post('update_profile_image', formData, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Edita la descripcion del usuario
 * */
export const editDescription = (description) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    const data = {
        description: description
    }
    return new Promise((resolve, reject) => {
        axiosInstance.post('update_description', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Cambia el estado del usuario
 * */
export const changeStatus = (status) => {
    return new Promise((resolve, reject) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': localStorage.getItem('token')
        }

        const data = {
            status: status
        }

        axiosInstance.post('update_coach_status', data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Actualiza la clave publica del usuario
 * */
export const updatePublicKey = async (publickey) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        public_key: publickey
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/update_public_key`, data,{headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Guarda el id de onesignal
 * */
export const updateOneSignalId = async (oneSignalId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        one_signal_token: oneSignalId
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/save_one_signal_token`, data,{headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Guarda la clave privada en el servidor
 * */
export const savePrivateKey = (privateKey) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    const data = {
        key: privateKey
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/save_uk`, data, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge la clave privada del servidor
 * */
export const getPrivateKey = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': token
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/get_uk`, {}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Cambia la contraseña del usuario
 */
export const changePassword = (password) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/change_user_password`, {password: password}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Edita la imagen del usuario
 */
export const changeProfileImage = (formData) => {
    const headers = {
        'Accept':'application/json',
        'Content-Type': 'multipart/form-data',
        'Authorization': localStorage.getItem('token')
    }

    return new Promise((resolve, reject) => {
        axios.post(`https://${Const.baseURL}/api/update_profile_image`, formData, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}
