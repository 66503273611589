import axios from 'axios';
import { Const } from '../utils/variables';

const axiosInstance = axios.create({baseURL: `https://${Const.baseURL}/api/`, responseType: 'json'});


/**
 * Recoge los articulos
 * */
export const getArticles = async () => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('post_categories', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las especialidades
 * */
export const getSpecialities = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('specialities', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge todos los coaches
 */
export const getCoaches = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get('coaches', {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge un coach por id
 */
export const getCoach = async (idCoach) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.get(`coaches/${idCoach}`, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge las sesiones cerradas del usuario
 */
export const getScheduleSessionsClose = async (userId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
    return new Promise((resolve, reject) => {
        axiosInstance.post(`getUserSessionClose`, {user_id: userId}, {headers}).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

/**
 * Recoge la session que tiene agendada el usuario
 * */
export const getScheduleSession = async (userId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post('/getUserSession', {user_id: userId}, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  /**
 * Recoge los chats con mensajes sin leer
 * */
export const getChannelsWithUnreadMessages = async (userId) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
  
    const data = {
      user_id: userId
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.post('/get_chats_with_unread_messages', data, {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  /**
   * Recoge los trending topics activos
   * */
  export const getActiveTrendingTopics = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.get('/get_active_trending_topics', {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  /**
 * Recoge un coach de ia
 * */
export const getIaUser = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token')
    }
  
    return new Promise((resolve, reject) => {
      axiosInstance.get('/get_ia_user', {headers}).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }
