import React, {useState, useRef, useEffect} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import * as moment from 'moment';
import io from 'socket.io-client';
import {animateScroll, Events} from "react-scroll";

import './chatIa.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import ChatItem from '../../components/chatItem/chatItem.component';
import Writer from '../../components/writer/writer.component';
import Message from '../../components/message/message.component';
import InfoUserCard from '../../components/infoUserCard/infoUserCard.component';
import MenuChat from '../../components/menuChat/menuChat.component';
import {getConversations, finishSession, changeStatusToEnterVideoCall, getVideocallUuid, userStartWriting, userEndWriting} from '../../services/chat.service';
import {getProfile} from "../../services/user.service";

import {sendMessage, getChannelMessages, blockUser, sendIaMessage} from '../../services/chat.service';
import Searcher from "../../components/searcher/searcher.component";
import { Crypt, RSA } from 'hybrid-crypto-js';
import sound from '../../assets/correct-ding.mp3'
import Avatar from '../../components/avatar/avatar.component';
import { createMessage, runIa, getRunState, getIaMessages, readAllIaMessages } from '../../services/ia.service';
import { sendUserIaMessage } from '../../services/chat.service';
import { createIAChat } from '../../services/chat.service';

import { Const } from '../../utils/variables';

import { getCoach } from '../../services/data.service';

const ChatIa = props => {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [coach, setCoach] = useState(null);
    const [showWriter, setShowWriter] = useState(true)
    const [isWriting, setIsWriting] = useState(false)

    const [chatSelected, setChatSelected] = useState(null);
    const [allChats, setAllChats] = useState(null)
    const [uuidSelected, setUuidSelected] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);

    const [showUserCard, setShowUserCard] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [chats, setChats] = useState(null);
    const [message, setMessage] = useState('');
    const [lastMessage, setLastMessage] = useState(null);
    const [idChat, setIdChat] = useState(null);
    const [idSession, setIdSession] = useState(null);
    const [idUser, setIdUser] = useState(null);

    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [actualConversation, setActualConversation] = useState();
    const [fileSended, setFileSended] = useState(false);
    const [messagesNotRead, setMessagesNotRead] = useState([]);
    const [recentlyMessage, setRecentlyMessage] = useState(null);

    const [conversationsOpened, setConversationsOpened] = useState([]);

    const [channelPrevId, setChannelPrevId] = useState(null);
    let isPageHidden = false;
    const [isHiddenPage, setIsHiddenPage] = useState(false);
    let chatId = null
    //const [channelNextId, setChannelNextId] = useState(location.state.channelId);


    const inputRef = useRef(null);
    //const socketRef = useRef(null);
    const scrollRef = useRef(null);
    let formData = new FormData();
    const audio = new Audio(sound)

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    useEffect(() => {
            let idNewChat = localStorage.getItem('idNewChat')
            console.log('idNewChat', idNewChat)
            setUser(localStorage.getItem('USER'))
            setCoach(location.state.coach)
            getCoachProfile()
        if (location.state.isNew && (idNewChat == null || idNewChat == 'null')) {
            setIsWriting(true)
            createIAChat(location.state.coach.id, location.state.thread_id, navigator.language, location.state.speciality).then(newChat => {
                localStorage.setItem('idNewChat', newChat.data.id)
                console.log('newChat', newChat)
                setIdChat(newChat.data.id)
                getMessagesChannel(newChat.data.id)
                setIsWriting(false)
            }).catch(err => {
                console.log('No se ha podido crear el nuevo chat', err)
            })
        } else {
            readAllIaMessages(location.state.idChat ? location.state.idChat : Number(idNewChat)).then(res => {
            }).catch(err => {
                console.log('No se han podido leer los mensajes')
            })
            //Hace que se vea el input para enviar el mensaje segun desde la vista que vengas
            setShowWriter(location.state.writer);
            setIdChat(location.state.idChat ? location.state.idChat : Number(idNewChat))
            getMessagesChannel(location.state.idChat ? location.state.idChat : Number(idNewChat))
            localStorage.setItem('chatSelected', JSON.stringify({chatId: location.state.idChat, coachId: location.state.coach.id}))
        }
    }, [])

    const getCoachProfile = () => {
        getCoach(location.state.coach.id).then(res => {
            setCoach(res.data);
            //console.log('coach recogido con exito', res.data);
        }).catch(err => {
            //console.log('Error al recoger el coach', err)
        })
    }

    function handleVisibilityChange() {
        if (document.hidden) {
          //console.log('La página está oculta. Posiblemente el sistema está inactivo.');
          isPageHidden = true;
          setIsHiddenPage(true);
          //socketRef?.current?.disconnect();
        } else {
          //console.log('La página está visible. Posiblemente el sistema se ha reactivado.');
          isPageHidden = false;
          setIsHiddenPage(false);
          window.location.reload();
        }
      }
      

    const playRing = () => {
        document.getElementById('miAudio').play()
    }

    //Recoge los mensajes de una conversacion al entrar
    const getMessagesChannel = (id) => {
        getChannelMessages(id).then(res => {
            setMessages(res.data.Messages.reverse());
            setActualConversation(res.data);
        }).catch(err => {
            //console.log('ERROR al recoger los mensajes', err);
        });
    }

    // Envia un nuevo mensaje
    const sendNewMessage = async (value) => {
        let messageOfInput = value.current.value
        // Si existe mensaje de texto o archvio
        if (value.current.value !== '') {
            //Manda el mensaje a la IA para que la IA cree su respuesta
            createMessage(value.current.value, location.state.thread_id).then(async resCreateMessage => {

                //Creamos el objeto del mensaje para añadirlo al array
                let arrayMessages = messages
                let newUserMessage = {
                    channel_id: idChat,
                    content: messageOfInput,
                    createdAt: new Date(),
                    id: arrayMessages.length > 0 ? arrayMessages[0].id + 1 : 0,
                    isSender: true,
                    user_id: user.id,
                    is_ia: true,
                    file: null,
                    fileType: null,
                    is_visible: true
                  }

                  let newMessage = '';
                    // Genera la instacia de CRYPT para encriptar el mensaje
                    let crypt = new Crypt();
                    let messageEncrypted;
                    // Recoge la clave publica de localstorage para encriptar el mensaje
                    let publicKeyAuthor = await localStorage.getItem('publickey');
                    // Encripta el mensaje con la clave publica dle usuario y del coach
                    messageEncrypted = await crypt.encrypt([publicKeyAuthor, actualConversation.channelUsers.coach.public_key], newMessage === '' ? messageOfInput : newMessage);

                    //Añadimos el nuevo mensaje al array sin encriptar
                    arrayMessages = [newUserMessage, ...arrayMessages]
                    setMessages(arrayMessages)
                    //Mostramos al user como que la IA esta escribiendo
                    setIsWriting(true)

                    sendUserIaMessage({}, idChat, user.id, null, messageEncrypted, messageOfInput).then(res => {
                        readAllIaMessages(idChat).then(res => {

                        }).catch(err => {
                            console.log('No se han podido leer los mensajes')
                        })
                        
                        let arrayMessages2 = messages
                        let newIAMessage = {
                            channel_id: idChat,
                            content: res.data,
                            createdAt: new Date(),
                            id: arrayMessages.length > 0 ? arrayMessages[0].id + 1 : 0,
                            isSender: true,
                            user_id: coach.id,
                            is_ia: true,
                            file: null,
                            fileType: null,
                            is_visible: true
                        }

                        //Añadimos el nuevo mensaje al array sin encriptar
                        arrayMessages2 = [newIAMessage, ...arrayMessages]
                        setMessages(arrayMessages2)

                        setIsWriting(false)
                    })





                    //Guardamos el mensaje enviado por el usuario en el servidor
                  /*sendIaMessage({}, location.state.idChat, user.id, null, messageEncrypted).then(res => {

                    //Creamos las instrucciones para la IA para saber con quien habla a que empresa pertence y que departamento de la misma manera saber quien es ella misma
                    let userParse = JSON.parse(user).user
                    let instructions = 'El usuario con el que hablas se llama ' + userParse.name + ' y trabaja en la empresa ' +  userParse.Organizations[0].name + ', tu sin embargo eres ' + coach.name + ' y trabajas para la empresa MyBeatCoach'
                    runIa(location.state.thread_id, instructions).then(resRunIa => {
                        //recogemos el estado en el que esta la creacion del mensaje de la IA
                        getIaStatus(resRunIa.data.id).then(async res => {
                            //Recogemos los mensajes de la IA
                            getIaMessages(location.state.thread_id).then(async res => {
                                //Cogemos el ultimo mensaje que ha creado la IA
                                let messageIA = res.data.data[0].content[0].text.value

                                // Genera la instacia de CRYPT para encriptar el mensaje
                                let crypt2 = new Crypt();
                                let messageEncryptedIA;
                                // Recoge la clave publica de localstorage para encriptar el mensaje
                                let publicKeyAuthor = await localStorage.getItem('publickey');
                                // Encripta el mensaje con la clave publica dle usuario y del coach
                                messageEncryptedIA = await crypt2.encrypt([publicKeyAuthor, actualConversation.channelUsers.coach.public_key], messageIA);

                                //Crea el objeto del mensaje de la IA para añadirlo al array
                                sendIaMessage({}, location.state.idChat, coach.id, null, messageEncryptedIA).then(res => {
                                    let arrayMessages2 = messages
                                    let newIAMessage = {
                                        channel_id: location.state.idChat,
                                        content: messageIA,
                                        createdAt: new Date(),
                                        id: arrayMessages.length > 0 ? arrayMessages[0].id + 1 : 0,
                                        isSender: true,
                                        user_id: coach.id,
                                        is_ia: true,
                                        file: null,
                                        fileType: null,
                                        is_visible: true
                                    }

                                    //Añadimos el nuevo mensaje al array sin encriptar
                                    arrayMessages2 = [newIAMessage, ...arrayMessages]
                                    setMessages(arrayMessages2)

                                    setIsWriting(false)
                                }).catch(err => {
                                    //console.log('Error send IA message')
                                })

                            }).catch(err => {
                                //console.log('Error getting IA messages', err)
                            })
                        })
                    }).catch(err => {
                        //console.log('Error run IA', err)
                    })

                  }).catch(err => {
                    //console.log('error send message user to server', err)
                    //console.log('Error creating IA Message', err)

                    getCoachProfile()
                    //console.log('ERROR al enviar el mensaje', err);
                    if (alert(err.response.data.error)) {
                        setMessage('');
                        setTypeData(null);
                        setFile(null);
                    } else {
                        setMessage('');
                        setTypeData(null);
                        setFile(null);
                    }

                    if (err.response.status == 469) {
                        props.navigation.goBack()
                        return
                      }
                  })

            }).catch(err => {
                //console.log('Error creating IA Message', err)
                getCoachProfile()
                //console.log('ERROR al enviar el mensaje', err);
                if (alert(err.response.data.error)) {
                    setMessage('');
                    setTypeData(null);
                    setFile(null);
                } else {
                    setMessage('');
                    setTypeData(null);
                    setFile(null);
                }
            })
        }*/
            }).catch(err => {
                console.log('err ia message', err)
            })
        }
    }

    //Devuelve al metodo de enviar mensaje el estado en el que se encuentra el proceso del mensaje de la IA
  const getIaStatus = (runId) => {
    return new Promise((resolve, reject) => {
      iaStatus(runId).then(res => {
        if (res == 'completed') {
          resolve(res)
        } else {
          reject(res)
        }
      })
    })
  }

  //Devuelve al metodo de getIaStatus el estado de la IA para comprbar en que estado esta y saber que hacer
  const iaStatus = async (runId) => {
    let response = null
    do {
      await iaStatusPromise(runId).then(res => {
        response = res
      })

    } while (response == null)

    return response
  }

  const iaStatusPromise = (runId) => {
    return new Promise((resolve, reject) => {
      getRunState(location.state.thread_id, runId).then(res => {
        switch (res.data.status) {
          case 'completed':
            resolve('completed')
            break;
          case 'in_progress':
            resolve(null)
            break;
          case 'queued':
            resolve(null)
            //response = 'cancelled'
            break;
          case 'expired':
            resolve(null)
            //response = 'expired'
            break;
          case 'failed':
            resolve(null)
            //response = 'failed'
            break;
          case 'incomplete':
            resolve(null)
            //response = 'cancelled'
            break;
          default:
            resolve(null)
        }
      }).catch(err => {
        //console.log('error comprbando el status de la IA')
      })
    })
  }


    // Maneja los archivos abiertos para poder mostrarlos y enviarlos
    const getFileObject = (value) => {
        //console.log('return open')
        setShowMenu(false);
        let file = document.getElementById('inputFile').files[0];
        let type = file.type.split('/');
        //console.log('TYPE', type)
        switch (type[0]) {
            case 'image':
                setTypeData('image');
            break;
            case 'video':
                setTypeData('video');
            break;
            case 'application':
                setTypeData('document');
            break;
        }
        setFile(value.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(value.target.files[0]);
        reader.onload = () => {
            setFilePreview(reader.result);
        }
    }

    // Filtra los chats por el texto introducido en el buscador
    const searchChats = (value) => {
        if (value !== '') {
            let newChats = chats.filter(chat => {
                if (chat.channelUsers.user.name.toLowerCase().includes(value.toLowerCase())) return chat;
            })
            setChats(newChats);
        }
        else {
            setChats(allChats);
        }
    }

    // ENtra a una videollamada
    const enterVideoCall = async (uuidSession) => {
        // LLama al servidor para recoger el id de la videollamada a la que se va a entrar
        await getVideocallUuid(idChat).then(async resp => {
            // Recoge el token de localstorage
            let token = await localStorage.getItem('token');
            // Abre una nueva ventana con el videochat (Se abre de esta manera para que ningun navegador bloque la ventana emergente)
            const a = document.createElement("a")
            a.href = `https://videochat.mybeatcoach.com/sfu/${resp.data}/${token}`
            a.target = "_blank"
            a.rel = "noopener"
            a.click()
        }).catch(err => {
            console.log('Error al recoger el id de la sesion', err);
        })
    }

    // Desencripta un mensaje
    const decriptMessage = (message) => {
        // Recoge la clave privada de localstorage para desencriptar el mensaje
        let privateKey = localStorage.getItem('privatekey');
        let crypt = new Crypt();
        try {
            let messageDecripted = crypt.decrypt(privateKey, message);
            return messageDecripted.message;
        } catch (err) {
            console.log('catch decript', err)
        }
    }

    //Envia al server si el usuario esta escribiendo para mostrarselo al otro usuario
    const userIsWriting = (value) => {
        if (value) {
            userStartWriting(idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('Error al empezar a escribir')
            })
        } else {
            userEndWriting(idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('El usuario no ha podido dejar de escribir', err)
            })
        }
    }


    return (
        <>
        <div className={'containerBlocksChat'}>

            {showMenu &&
                <MenuChat
                    inputRef={inputRef}
                    //initVideochat={() => initVideochat()}
                    //finishSession={() => closeSession()}
                    pressNoMenu={() => setShowMenu(false)}
                    idUser={idUser}
                    idChannel={chatSelected}
                />
            }
            <input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => getFileObject(value)}/>


            <div className={'containerChatChat'}>
                {coach &&
                    <div className={'headerChat'}>
                    
                        <Avatar
                        style={{marginLeft: 40}}
                        image={coach.image === null ? ImageAssets.userTest : `${coach.image}`}
                        status={coach.status}
                        />
                        <div>
                            <p className={'nameUserChat'}  onClick={() => {
                                //setShowUserCard(!showUserCard);
                                //setShowMenu(false);
                            }}>{`${coach.name} ${coach.lastname}`}</p>

                            {isWriting ?
                                <p className={'textWritingChat'}>Escribiendo...</p>
                                :
                                <p className={'textNoWritingChat'}>a</p>
                            }
                        </div>
                        {/*<img className={'iconOptionsChat'} src={IconAssets.options} onClick={() => {
                            setShowMenu(!showMenu);
                            setShowUserCard(false);
                        }}/>*/}
                    </div>
                }
                <div className={'containerChat'}>
                    {typeData === null ?
                        <div id={'containerMessages'} ref={scrollRef} className={'containerMessagesChat'} onLoad={() => console.log('')}>
                            {messages.length > 0 &&
                            (messages.map(message => {
                                    {console.log('message', message)}
                                    if (message.is_visible) {
                                        return (
                                            <Message
                                                sender={Number(message.user_id) != Number(coach.id)}
                                                message={message.content}
                                                idChat={message.channel_id}
                                                type={message.message_type}
                                                time={moment(message.createdAt).format('HH:mm')}
                                                file={message.file}
                                                pressFile={value => setFilePreview(value)}
                                                typeFile={value => setTypeData(value)}
                                                isSended={value => setFileSended(value)}
                                                isIa={message.is_ia}
                                                press={() => {
                                                    enterVideoCall(message.videocall_uuid)
                                                }}
                                            />
                                        )
                                    }
                                    
                                })
                            )
                            }
                        </div>
                        :
                        <div className={'containerFilesChat'}>
                            {typeData === 'image' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <img className={'imageFile'} src={filePreview}/>
                                </>
                            }
                            {typeData === 'video' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <video style={{width: '100%', height: '90%'}} src={filePreview} controls/>
                                </>
                            }
                            {typeData === 'document' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <iframe src={filePreview+'#toolbar=0'} width={'100%'} height={'90%'}/>
                                </>
                            }
                        </div>
                    }


                    {(!fileSended && showWriter)&&
                        <Writer
                            //value={message}
                            //changeValue={value => setMessage(value)}
                            canWrite={isWriting}
                            writing={value => userIsWriting(value)}
                            pressSender={(value) => sendNewMessage(value)}
                            sendMessage={(value) => sendNewMessage(value)}
                        />
                    }

                </div>
            </div>

        </div>
        </>
    )
}

export default ChatIa;
