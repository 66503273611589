import React from 'react';

import './avatar.css';
import { ImageAssets } from '../../utils/ImageAssets';
import NotUser from '../../assets/img/notUser.png'
import { Const } from '../../utils/variables';
import { IconAssets } from '../../utils/ImageAssets';

// Muestra las imagenes de los usuarios
const Avatar = ({style, image, status, hiddenStatus}) => {
    

    return (
        <div style={style} className='containerAvatar'>
            <img className={'imageNavBar'} src={image ? (image.includes('https') ? image :`https://${Const.baseURL}/${image}`) : NotUser}/>


            {/*Segun el estado que llegue muestra el el punto de un color*/}
            {!hiddenStatus ?
                (status == 'Online' || status == 'Disponible' ?
                    <div className={'dotAvatarConnected'}></div>
                    :
                    (status === 'Busy') ?
                    <div className={'dotAvatarOcupado'}></div>
                        :
                    <div className={'dotAvatarDisconnected'}></div>
                )
                :
                null
            }
        </div>
    )
}

export default Avatar;
