import React from 'react';

import './menu.css';
import {IconAssets} from '../../utils/ImageAssets';

import {useNavigate} from 'react-router-dom';

import DotQuantity from '../dotQuantity/dotQuantity.component';

import { userEndWriting } from '../../services/chat.service';

import {useTranslation} from "react-i18next";

// Muestra el componente del menu dentro del navbar
const Menu = ({route, unreadMessages}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const chatEndWriting = () => {
        let chat = localStorage.getItem('chatSelected')
        if (chat != null && chat != undefined && chat != 'null' && chat != '') {
            userEndWriting(JSON.parse(chat).chatId, JSON.parse(chat).coachId).then(res => {
                localStorage.setItem('chatSelected', null)
            }).catch(err => {
                localStorage.setItem('chatSelected', null)
                //console.log('No se ha podido dejar de escribir en el chat')
            })
        }
    }

    // Segun la ruta que venga por paremetros se muestran los items del menu de una manera u otra
    return (
        <div className={'containerMenu'}>
            <div className={(route === '/' || route === '/home') ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/home')
                }}>
                <img className={'iconMenuItem'} src={(route === '/' || route === '/home') ? IconAssets.whiteHouse : IconAssets.greyHouse}/>
                <p className={route === '/' || route === '/home' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Home</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={unreadMessages} focus={route === '/' || route === '/home'}/>
            </div>

            {/*<div className={(route === '/' || route === '/coaches') ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/coaches')
                }}>
                <img className={'iconMenuItem'} src={(route === '/' || route === '/coaches') ? IconAssets.profileFocus : IconAssets.profileNoFocus}/>
                <p className={route === '/' || route === '/coaches' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Coaches</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={unreadMessages} focus={route === '/' || route === '/coaches'}/>
            </div>*/}

            <div className={(route === '/chats') ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/chats')
                }}>
                <img className={'iconMenuItem'} src={(route === '/chats') ? IconAssets.messagesFocus : IconAssets.messagesNoFocus}/>
                <p className={route === '/chats' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>{t('messages')}</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={unreadMessages} focus={route === '/chats'}/>
            </div>

            {/*<div className={route === '/diary' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/diary')}>
                <img className={'iconMenuItem'} src={route === '/diary' ? IconAssets.agendaFocus : IconAssets.agendaNoFocus}/>
                <p className={route === '/diary' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Agenda</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/diary'}/>
            </div>*/}



            <div className={route === '/sessions' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/sessions')
                }}>
                <img className={'iconMenuItem'} src={route === '/sessions' ? IconAssets.whiteCam : IconAssets.greyCam}/>
                <p className={route === '/sessions' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>{t('sessions')}</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/sessions'}/>
            </div>


            <div className={route === '/history' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/history')
                }}>
                <img className={'iconMenuItem'} src={route === '/history' ? IconAssets.historyFocus : IconAssets.historyNoFocus}/>
                <p className={route === '/history' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>{t('history')}</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/history'}/>
            </div>


            {/*<div className={route === '/articles' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/articles')}>
                <img className={'iconMenuItem'} src={route === '/articles' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>
                <p className={route === '/articles' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Artículos</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/articles'}/>
            </div>*/}


            {/*<div className={route === '/profile' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => navigate('/profile')}>
                <img className={'iconMenuItem'} src={route === '/profile' ? IconAssets.profileFocus : IconAssets.profileNoFocus}/>
                <p className={route === '/profile' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>Perfil</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/profile'}/>
            </div>*/}

            <div className={route === '/about' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/about')
                }}>
                <img className={'iconMenuItem'} src={route === '/about' ? IconAssets.articlesFocus : IconAssets.articlesNoFocus}/>
                <p className={route === '/about' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>{t('aboutMbc')}</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/about'}/>
            </div>

            <div className={route === '/faq' ? 'MenuItem' : 'MenuItemNoFocus'} onClick={() => {
                    chatEndWriting()
                    navigate('/faq')
                }}>
                <img className={'iconMenuItem'} src={route === '/faq' ? IconAssets.agendaFocus : IconAssets.agendaNoFocus}/>
                <p className={route === '/faq' ? 'textMenuItemFocus' : 'textMenuItemNoFocus'}>{t('faq')}</p>
                <DotQuantity style={{marginRight: 13}} size={'little'} quantity={0} focus={route === '/faq'}/>
            </div>

        </div>
    )
}

export default Menu;
