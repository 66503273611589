import React from 'react';

import './rememberPassword.css';
import {ImageAssets} from '../../../utils/ImageAssets';

import {useLocation, useNavigate} from 'react-router-dom';
import {resetPassword} from '../../../services/user.service';


import Button from '../../../components/button/button.component';
import {  message } from 'antd';
// Vista informacion para cambiar contraseña
const FirstStep = () => {
    const location = useLocation();
    const navigate = useNavigate();





    // Resetea la password
    const resetPasswordFn = () => {
        
        resetPassword(location.state.email).then(user => {
            //console.log('Password reseteada con éxito');
            message.success('Tu contraseña se ha reseteado con éxito. Mira en tu bandeja de correo. Si no encuentras el email, revisa tu bandeja de spam.');
            navigate('/');
        }).catch(err => {
            console.log('ERROR al recoger el perfil', err);
        })
    }

    // Al haber escrito el email en la pantalla de login y darle al recordar contraseña llegas a esta pantalla en la que podras volver a navegar
    // al login o seguir recordando contraseña
    return (
        <div className={'containerRememberPassword'}>
            <div className={'containerFirstStep'}>
                <img className={'logoFirstStep'} src={ImageAssets.originalLogoAuth}/>

                <p className={'textFirstStep'}>Te enviaremos una nueva contraseña a {location.state.email}</p>

                <div className={'containerButtonsFirstStep'}>
                    <div className={'containerButtonFS'}>
                        <Button
                            text={'Cancelar'}
                            secondary
                            press={() => navigate('/')}
                        />
                    </div>

                    <div className={'containerButtonFS'}>
                        <Button
                            text={'Aceptar'}
                            press={() =>resetPasswordFn()} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FirstStep;
