import React, {useState, useRef, useEffect} from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import * as moment from 'moment';
import io from 'socket.io-client';
import {animateScroll, Events} from "react-scroll";

import './chat.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import ChatItem from '../../components/chatItem/chatItem.component';
import Writer from '../../components/writer/writer.component';
import Message from '../../components/message/message.component';
import MenuChat from '../../components/menuChat/menuChat.component';
import {getConversations, finishSession, changeStatusToEnterVideoCall, getVideocallUuid, userStartWriting, userEndWriting} from '../../services/chat.service';
import {getProfile} from "../../services/user.service";

import {sendMessage, getChannelMessages, blockUser} from '../../services/chat.service';
import Searcher from "../../components/searcher/searcher.component";
import { Crypt, RSA } from 'hybrid-crypto-js';
import sound from '../../assets/correct-ding.mp3'
import Avatar from '../../components/avatar/avatar.component';

import { Const } from '../../utils/variables';

import { getCoach } from '../../services/data.service';
import {useTranslation} from "react-i18next";

const Chat = props => {
    const {id} = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [coach, setCoach] = useState(null);
    const [showWriter, setShowWriter] = useState(true)
    const [isWriting, setIsWriting] = useState(false)

    const [chatSelected, setChatSelected] = useState(null);
    const [allChats, setAllChats] = useState(null)
    const [uuidSelected, setUuidSelected] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);

    const [showUserCard, setShowUserCard] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [chats, setChats] = useState(null);
    const [message, setMessage] = useState('');
    const [lastMessage, setLastMessage] = useState(null);
    const [idChat, setIdChat] = useState(null);
    const [idSession, setIdSession] = useState(null);
    const [idUser, setIdUser] = useState(null);

    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [typeData, setTypeData] = useState(null);
    const [actualConversation, setActualConversation] = useState();
    const [fileSended, setFileSended] = useState(false);
    const [messagesNotRead, setMessagesNotRead] = useState([]);
    const [recentlyMessage, setRecentlyMessage] = useState(null);

    const [conversationsOpened, setConversationsOpened] = useState([]);

    const [channelPrevId, setChannelPrevId] = useState(null);
    let isPageHidden = false;
    const [isHiddenPage, setIsHiddenPage] = useState(false);
    let chatId = null
    //const [channelNextId, setChannelNextId] = useState(location.state.channelId);


    const inputRef = useRef(null);
    const socketRef = useRef(null);
    const scrollRef = useRef(null);
    let formData = new FormData();
    const audio = new Audio(sound)

    const {t} = useTranslation();

    document.addEventListener('visibilitychange', handleVisibilityChange, false);

    useEffect(() => {
        setUser(localStorage.getItem('USER'))
        setCoach(location.state.coach)
        getMessagesChannel(location.state.idChat)
        getCoachProfile()
        localStorage.setItem('chatSelected', JSON.stringify({chatId: location.state.idChat, coachId: location.state.coach.id}))
    }, [])

    useEffect(() => {
        //Hace que se vea el input para enviar el mensaje segun desde la vista que vengas
        setShowWriter(location.state.writer);
        //CONECTA EL SOCKET
        let token = localStorage.getItem('token');
        socketRef.current = io(`https://${Const.baseURL}/nsp-io-chat`, {query: `token=${token}`});
        //console.log('after connect socket')
        socketEvents(1);

        /*console.log('this is the first console log')
        //RECOGE LAS CONVERSACIONES ABIERTAS
        getConversations(1).then(conversations => {
            setAllChats(conversations.data);
            setChats(conversations.data);

            

            //OBTIENE LOS EVENTOS DEL SOCKET
            getProfile().then(res => {
                console.log('getProfileHome', res.data);
                socketEvents(res.data.id);
                setCurrentUserId(res.data.id);
            }).catch(err => {
                console.log('Error to get profile',err);
            })
        }).catch(err => {
            console.log('ERROR al recoger las conversaciones', err);
        })*/

        return () => {
            socketRef?.current?.disconnect();
        }
    }, []);

    const getCoachProfile = () => {
        getCoach(location.state.coach.id).then(res => {
            setCoach(res.data);
            //console.log('coach recogido con exito', res.data);
        }).catch(err => {
            console.log('Error al recoger el coach', err)
        })
    }

    function handleVisibilityChange() {
        if (document.hidden) {
          //console.log('La página está oculta. Posiblemente el sistema está inactivo.');
          isPageHidden = true;
          setIsHiddenPage(true);
          socketRef?.current?.disconnect();
        } else {
          //console.log('La página está visible. Posiblemente el sistema se ha reactivado.');
          isPageHidden = false;
          setIsHiddenPage(false);
          window.location.reload();
        }
      }
      

    // Antes de eliminar la pagina desconecta el socket
    window.onbeforeunload = function() {
        socketRef?.current?.disconnect();
    };

    // Se ejecuta cada vez que cambias de conversacion en el chat
    /*useEffect(() => {
        setTimeout(() => {
            // Desactiva el evento del socket para recibir mensajes
            socketRef?.current?.off('message-published');
            // Vulve a conectar con el evento del socket para recibir mensajes
            
        }, 500)
    }, [chatSelected]);*/

    /*useEffect(() => {
        if (recentlyMessage) {
            console.log('THIS IS A RECENTLY MESSAGE', recentlyMessage);
        }

    }, [recentlyMessage])*/

    const socketEvents = (coachId) => {
        
        //ENVIAMOS EL CHANNELID A NULL PARA HACER EL JOIN SOBRE TODAS LAS CONVERSACIONES NO SOBRE UNA CONCRETA
        socketRef.current.emit('join', {
            channelId: location.state.idChat,
            userId: coachId,
            //email: 'rmorenor97@gmail.com',
            socketId: socketRef?.current?.id,
            platform: 'web',
            appVersion: 'web'
        });

        socketRef?.current?.on('connect', () => {
            //log('CONECT TO SOCKET', socketRef.current.id);
        });

        socketRef?.current?.on('welcome', () => {
            //console.log('WELCOME TO SOCKET');
        });

        /*socketRef.current?.emit('coach-last-channel', {
            channel_prev_id: null,
            channel_next_id: location.state.channelId
        })*/

        socketRef?.current?.on('message-published', (e) => {
            //console.log('enter in message published', e)
            // Si la conversacion en la que estas actualmente y la que te llega por el nuevo mensaje publicado son la smismas
            //if (Number(e.channel_id) === Number(chatSelected)) {
                // Recoge el usuario de localstorage
                let userStorage = localStorage.getItem('USER');
                // Marca el mensaje como leido
                //socketRef?.current?.emit('read-message', {messageId: e.id, userId: JSON.parse(userStorage).user.id})
                // Añade el mensaje al array para mostrarlo en la vista
                setMessages(messages => [e, ...messages]);
            /*} else {
                // SI la conversacion qu ellega por el mensaje no es la misma en la qu estamos actualmete
                // Vuelve a recoger las conversaciones para mostrar sus ultimos mensajes actualizados
                getConversations(1).then(conversations => {
                    setAllChats(conversations.data);
                    setChats(conversations.data);
                }).catch(err => {
                    console.log('ERROR al recoger las conversaciones en el socket', err)
                })
            }*/
        });

        /*Detecta cuando el coach a cerrado la conversacion*/
        socketRef?.current?.on('chat-ended', (e) => {
            navigate('/rating', {state: {idChat: location.state.idChat, coach: location.state.coach}})
        
        })

        socketRef?.current?.on('unread-messages', (e) => {
            //console.log('LOS MENSAJES SIN LEER', e);
            //console.log('chatIdddd', chatId)
            if (e.channels.length > 0) {
                audio.play()
            }
            setMessagesNotRead(e.channels);
        })

        socketRef?.current?.on('new-channel', (e) => {
            socketRef?.current?.off('join_all');
            socketRef.current.emit('join_all', {
                channelId: null,
                userId: coachId,
                email: 'rmorenor97@gmail.com',
                socketId: socketRef.current.id,
                platform: 'web',
                appVersion: 'web'
            });
            getConversations(1).then(conversations => {
                setAllChats(conversations.data);
                setChats(conversations.data);
            }).catch(err => {
                console.log('ERROR al recoger las conversaciones en el socket', err)
            })
        })

        socketRef?.current?.on('start_writing', (e) => {
            let userStorage = localStorage.getItem('USER');
            if (e.user_id == JSON.parse(userStorage).user.id && e.channel_id == location.state.idChat) {
                setIsWriting(true);
                //console.log('el usuario esta escribiendo')
            }
        });
        socketRef?.current?.on('end_writing', (e) => {
            let userStorage = localStorage.getItem('USER');
            if (e.user_id == JSON.parse(userStorage).user.id && e.channel_id == location.state.idChat) {
                setIsWriting(false);
                //console.log('el usuario ha dejado de escribir')
            }
        });

        socketRef?.current?.on('videocall-started', (e) => {
            // window.open(`https://videochat.innobing.net/sfu/${e.videocall_uuid}/${token}/`);
            //console.log('videocall started',e);
            //console.log('changes event',e);
            setIdSession(e.videocall_uuid)
        });

        socketRef?.current?.on('error', () => {
            //console.log('CONECT SOCKET ERROR');
        });

        socketRef?.current?.on('reconnect', (attemptNumber) => {
        //console.log(`Intento de reconexión número ${attemptNumber}`);
        });
        
        socketRef?.current?.on('reconnect_error', (error) => {
        //console.error('Error durante la reconexión:', error);
        });

        socketRef?.current?.on('error', (e) => {
            //console.log('CONECT SOCKET error', e);
            socketRef?.current?.disconnect();
            let token =localStorage.getItem('token');
            //console.log('ENTER IN SOCKET CONNECT', token)
            socketRef.current = io(`https://${Const.baseURL}/nsp-io-chat`, {query: `token=${token}`});
            socketEvents(currentUserId);
        });

        socketRef?.current?.on('disconnect', () => {
            //console.log('CONECT SOCKET DISCONNECT');
        });
    }

    const playRing = () => {
        document.getElementById('miAudio').play()
    }

    //Recoge los mensajes de una conversacion al entrar
    const getMessagesChannel = (id) => {
        getChannelMessages(id).then(res => {
            //console.log('Mensajes recogidos con exito', res.data);
            setMessages(res.data.Messages.reverse());
            setActualConversation(res.data);
            /*let conversations = [];
            chats.map(chat => {
                if (chat.id !== id) {
                    conversations.push(chat);
                } else {
                    conversations.push(res.data);
                }
            })
            setAllChats(conversations);
            setChats(conversations);*/
        }).catch(err => {
            //console.log('ERROR al recoger los mensajes', err);
        });
    }

    // Envia un nuevo mensaje
    const sendNewMessage = async (value) => {
        // Si existe mensaje de texto o archvio
        if (value.current.value !== '' || file !== null) {
            // Si existe archivo adjunta al texto que pueda tener el mensaje la fecha hasta la que estara disponible el archivo
            let newMessage = '';
            if (file !== null) {
                let expirationDate = moment().add(30, 'days').toDate().toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })
                newMessage = value.current.value + " (Este fichero adjunto estará disponible hasta el " + expirationDate+")";
            }
            // Genera la instacia de CRYPT para encriptar el mensaje
            let crypt = new Crypt();
            let messageEncrypted;
            // Recoge la clave publica de localstorage para encriptar el mensaje
            let publicKeyAuthor = localStorage.getItem('publickey');
            // Encripta el mensaje con la clave publica dle usuario y del coach
            messageEncrypted = await crypt.encrypt([publicKeyAuthor, actualConversation.channelUsers.coach.public_key], newMessage === '' ? value.current.value : newMessage);
            // Envia el mensaje al servidor
            sendMessage(location.state.idChat, user.id, messageEncrypted, file).then(res => {
                //console.log('mensaje enviado con exito')
                // Elimian todas la variables para poder enviar un nuevo mensaje de 0
                setMessage('');
                setTypeData(null);
                setFile(null);
                userEndWriting(location.state.idChat, coach.id).then(res => {
                    //console.log('el usuario ha dejado de escribir')
                }).catch(err => {
                    //console.log('El usuario no ha podido dejar de escribir', err)
                })
                //console.log('Mensaje enviado con exito', res);
            }).catch(err => {
                getCoachProfile()
                //console.log('ERROR al enviar el mensaje', err);
                if (alert(err.response.data.error)) {
                    setMessage('');
                    setTypeData(null);
                    setFile(null);
                } else {
                    setMessage('');
                    setTypeData(null);
                    setFile(null);
                }
            })
        }
    }

    // Envia al servidor que se va a iniciar una videollamada
    const initVideochat = () => {
        socketRef?.current?.emit('initVideochat', {channelId: chatSelected});
    }

    // Cierra una sesión
    const closeSession = () => {
        // Envia al servidor el id de la sesion que se va a cerrar
        finishSession(chatSelected).then(res => {
            //console.log('Sesion finalizada con exito', res);
            socketRef?.current?.emit('leave-channel', {channelId: chatSelected});
            // Navega a la pagina para valorar
            navigate('/rating', {state: {idChat: chatSelected}});
        }).catch(err => {
            //console.log('ERROR al finalizar la sesion', err);
        })
    }

    // Maneja los archivos abiertos para poder mostrarlos y enviarlos
    const getFileObject = (value) => {
        //console.log('return open')
        setShowMenu(false);
        let file = document.getElementById('inputFile').files[0];
        let type = file.type.split('/');
        //console.log('TYPE', type)
        switch (type[0]) {
            case 'image':
                setTypeData('image');
            break;
            case 'video':
                setTypeData('video');
            break;
            case 'application':
                setTypeData('document');
            break;
        }
        setFile(value.target.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(value.target.files[0]);
        reader.onload = () => {
            setFilePreview(reader.result);
        }
    }

    // Filtra los chats por el texto introducido en el buscador
    /*const searchChats = (value) => {
        if (value !== '') {
            let newChats = chats.filter(chat => {
                if (chat.channelUsers.user.name.toLowerCase().includes(value.toLowerCase())) return chat;
            })
            setChats(newChats);
        }
        else {
            setChats(allChats);
        }
    }*/

    // ENtra a una videollamada
    const enterVideoCall = async (uuidSession) => {
        // LLama al servidor para recoger el id de la videollamada a la que se va a entrar
        await getVideocallUuid(location.state.idChat).then(async resp => {
            // Recoge el token de localstorage
            let token = await localStorage.getItem('token');
            // Abre una nueva ventana con el videochat (Se abre de esta manera para que ningun navegador bloque la ventana emergente)
            const a = document.createElement("a")
            a.href = `https://videochat.mybeatcoach.com/sfu/${resp.data}/${token}/null`
            a.target = "_blank"
            a.rel = "noopener"
            a.click()
        }).catch(err => {
            //console.log('Error al recoger el id de la sesion', err);
        })
    }

    // Desencripta un mensaje
    const decriptMessage = (message) => {
        // Recoge la clave privada de localstorage para desencriptar el mensaje
        let privateKey = localStorage.getItem('privatekey');
        let crypt = new Crypt();
        try {
            let messageDecripted = crypt.decrypt(privateKey, message);
            return messageDecripted.message;
        } catch (err) {
            //console.log('catch decript', err)
        }
    }

    //Envia al server si el usuario esta escribiendo para mostrarselo al otro usuario
    const userIsWriting = (value) => {
        if (value) {
            userStartWriting(location.state.idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('Error al empezar a escribir')
            })
        } else {
            userEndWriting(location.state.idChat, coach.id).then(res => {
            }).catch(err => {
                //console.log('El usuario no ha podido dejar de escribir', err)
            })
        }
    }


    return (
        <>
        <div className={'containerBlocksChat'}>
            {/*showUserCard &&
                <InfoUserCard
                    user={actualConversation}
                    close={value => setShowUserCard(value)}
                />
    */}

            {showMenu &&
                <MenuChat
                    inputRef={inputRef}
                    initVideochat={() => initVideochat()}
                    finishSession={() => closeSession()}
                    pressNoMenu={() => setShowMenu(false)}
                    idUser={idUser}
                    idChannel={chatSelected}
                />
            }
            <input id={'inputFile'} ref={inputRef} hidden={true} type={'file'} onChange={value => getFileObject(value)}/>

            {/*<div className={'blockChatsChat'}>
                <div className={'headerChat'}>
                    <img className={'iconBackChat'} src={IconAssets.back} onClick={() => {
                        setChatSelected(null);
                    }}/>
                </div>
                {chats !== null &&
                    <div className={'containerChatsChat'}>
                        {chats.map((chat, index) => {

                            return (
                                <ChatItem
                                    image={chat.channelUsers.user.image}
                                    fullName={`${chat.channelUsers.user.name} ${chat.channelUsers.user.lastname}`}
                                    lastMessage={lastMessage !== null && chat.id === idChat ? decriptMessage(lastMessage.content) : (chat.Messages.length > 0 ? decriptMessage(chat.Messages[0].content) : '')}
                                    size={'little'}
                                    organization={(chat.channelUsers.user.Organizations && chat.channelUsers.user.Organizations.length > 0) ? chat.channelUsers.user.Organizations[0].name : 'Desconocida'}
                                    unreadMessages={messagesNotRead.find(object => object.channel_id === chat.id)}
                                    timeLastMessage={chat.Messages.length > 0 ? chat.Messages[0].createdAt : ''}
                                    press={() => {
                                        if (chat.id !== chatSelected) {
                                            socketRef?.current?.emit('join_channel', {channelId: chat.id});
                                            setChatSelected(chat.id);
                                            getMessagesChannel(chat.id)
                                            setUuidSelected(chat.videocall_uuid);
                                            setIdUser(chat.channelUsers.user.id);
                                        }
                                        /*if (chat.id !== channelNextId) {
                                            console.log('idCHAAAAAAAT', channelNextId)
                                            setChannelPrevId(location.state.channelId);
                                            setChannelNextId(chat.id);
                                            setIdChat(chat.id);

                                            getMessagesChannel(chat.id);

                                            socketRef.current?.emit('coach-last-channel', {
                                                channel_prev_id: location.state.channelId,
                                                channel_next_id: chat.id
                                            })
                                            location.state.channelId = chat.id
                                        }
                                    }}
                                />
                            )
                        })
                        }
                    </div>
                }
            </div>*/}

            <div className={'containerChatChat'}>
                {actualConversation &&
                    <div className={'headerChat'}>
                    
                        <Avatar
                        style={{marginLeft: 40}}
                        image={coach.image === null ? ImageAssets.userTest : `${coach.image}`}
                        status={coach.status}
                        />
                        <div>
                            <p className={'nameUserChat'}  onClick={() => {
                                //setShowUserCard(!showUserCard);
                                //setShowMenu(false);
                            }}>{`${coach.name} ${coach.lastname}`}</p>

                            {isWriting ?
                                <p className={'textWritingChat'}>{t('typing')}</p>
                                :
                                <p className={'textNoWritingChat'}>No escribiendo</p>
                            }
                        </div>
                        {/*<img className={'iconOptionsChat'} src={IconAssets.options} onClick={() => {
                            setShowMenu(!showMenu);
                            setShowUserCard(false);
                        }}/>*/}
                    </div>
                }
                <div className={'containerChat'}>
                    {typeData === null ?
                        <div id={'containerMessages'} ref={scrollRef} className={'containerMessagesChat'} onLoad={() => console.log('')}>
                            {messages.length > 0 &&
                            (messages.map(message => {
                                    return (
                                        <Message
                                            sender={Number(message.user_id) != Number(coach.id)}
                                            message={message.content}
                                            idChat={message.channel_id}
                                            type={message.message_type}
                                            time={moment(message.createdAt).format('HH:mm')}
                                            file={message.file}
                                            pressFile={value => setFilePreview(value)}
                                            typeFile={value => setTypeData(value)}
                                            isSended={value => setFileSended(value)}
                                            press={() => {
                                                enterVideoCall(message.videocall_uuid)
                                            }}
                                        />
                                    )
                                })
                            )
                            }
                        </div>
                        :
                        <div className={'containerFilesChat'}>
                            {typeData === 'image' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <img className={'imageFile'} src={filePreview}/>
                                </>
                            }
                            {typeData === 'video' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <video style={{width: '100%', height: '90%'}} src={filePreview} controls/>
                                </>
                            }
                            {typeData === 'document' &&
                                <>
                                    <img className={'iconCloseChat'} src={IconAssets.close} onClick={() => {
                                        setTypeData(null);
                                        setFile(null);
                                        setFilePreview(null);
                                        setFileSended(false);
                                    }}/>
                                    <iframe src={filePreview+'#toolbar=0'} width={'100%'} height={'90%'}/>
                                </>
                            }
                        </div>
                    }


                    {(!fileSended && showWriter)&&
                        <Writer
                            //value={message}
                            //changeValue={value => setMessage(value)}
                            writing={value => userIsWriting(value)}
                            pressSender={(value) => sendNewMessage(value)}
                            sendMessage={(value) => sendNewMessage(value)}
                        />
                    }

                </div>
            </div>

        </div>
        </>
    )
}

export default Chat;
