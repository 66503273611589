// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerChats {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    max-height: 100vh;
}

.titleChats {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemChats {
    overflow: scroll;
    width: 100%;
    margin-top: 50px;
    padding-bottom: 50px;
}

.floatButtonChats {
    position: absolute;
    right: 100px;
    bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    width: 60px;
    height: 50px;
    border-radius: 15px;
    background-color: #0f1840;
    color: #FFFFFF;
    font-size: 50px;
}`, "",{"version":3,"sources":["webpack://./src/views/chats/chats.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,eAAe;AACnB","sourcesContent":[".containerChats {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n    height: 100%;\n    max-height: 100vh;\n}\n\n.titleChats {\n    font-weight: 700;\n    font-size: 1.3vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.containerItemChats {\n    overflow: scroll;\n    width: 100%;\n    margin-top: 50px;\n    padding-bottom: 50px;\n}\n\n.floatButtonChats {\n    position: absolute;\n    right: 100px;\n    bottom: 100px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    padding-bottom: 10px;\n    width: 60px;\n    height: 50px;\n    border-radius: 15px;\n    background-color: #0f1840;\n    color: #FFFFFF;\n    font-size: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
