// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerFaq {
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100vh;
}

.titleFaq {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemsFaq {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/views/faq/faq.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;IACV,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,eAAe;AACnB","sourcesContent":[".containerFaq {\n    overflow: scroll;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 90%;\n    height: 100%;\n    max-height: 100vh;\n}\n\n.titleFaq {\n    font-weight: 700;\n    font-size: 1.3vw;\n    letter-spacing: 1px;\n    color: #000000;\n}\n\n.containerItemsFaq {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
