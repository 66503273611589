import i18n from 'i18next';
import  {initReactI18next} from 'react-i18next';
import translations from './translations';

// Maneja con el idioma que se esta utilizando la aplicacion
i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Idioma de reserva
    resources: translations,
    react: {
      useSuspense: false,
    },
  });

export default i18n;
