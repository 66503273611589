// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerAvatar {
    position: relative;
}

.imageNavBar {
    object-fit: cover;
}

.dotAvatarConnected {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #16C83D;
}

.dotAvatarDisconnected {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #D22614;
}

.dotAvatarOcupado {
    position: absolute;
    bottom: 0.35vw;
    left: 3.50vw;
    width: 1.2vw;
    height: 1.2vw;
    border-radius: 100px;
    background-color: #ffbf47;
}

.dotAvatarEdit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0.30vw;
    left: 3.60vw;
    width: 1.5vw;
    height: 1.5vw;
    border-radius: 100px;
    background-color: #0f1840;
}
`, "",{"version":3,"sources":["webpack://./src/components/avatar/avatar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,yBAAyB;AAC7B","sourcesContent":[".containerAvatar {\n    position: relative;\n}\n\n.imageNavBar {\n    object-fit: cover;\n}\n\n.dotAvatarConnected {\n    position: absolute;\n    bottom: 0.35vw;\n    left: 3.50vw;\n    width: 1.2vw;\n    height: 1.2vw;\n    border-radius: 100px;\n    background-color: #16C83D;\n}\n\n.dotAvatarDisconnected {\n    position: absolute;\n    bottom: 0.35vw;\n    left: 3.50vw;\n    width: 1.2vw;\n    height: 1.2vw;\n    border-radius: 100px;\n    background-color: #D22614;\n}\n\n.dotAvatarOcupado {\n    position: absolute;\n    bottom: 0.35vw;\n    left: 3.50vw;\n    width: 1.2vw;\n    height: 1.2vw;\n    border-radius: 100px;\n    background-color: #ffbf47;\n}\n\n.dotAvatarEdit {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    bottom: 0.30vw;\n    left: 3.60vw;\n    width: 1.5vw;\n    height: 1.5vw;\n    border-radius: 100px;\n    background-color: #0f1840;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
