import React from 'react'
import './scheduledSession.css'

import * as moment from 'moment';
import {useTranslation} from "react-i18next";
import { IconAssets } from '../../utils/ImageAssets';

const ScheduledSession = ({style, data, press}) => {
    moment.lang('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
        monthsShort: 'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_')
      }
    );
    const {t} = useTranslation()

    const fifteenMinutesReaming = () => {
        let currentDate = new Date()
        let timeLeft = new Date(data.start_date) - currentDate;
        let fifteenMinutes = 15 * 60 * 1000;
        if (timeLeft <= fifteenMinutes) {
          return true
        } else {
          return false
        }
      }

      const enterVideocall = async () => {
        // Recoge el token de localstorage
        let token = await localStorage.getItem('token');
        // Abre una nueva ventana con el videochat (Se abre de esta manera para que ningun navegador bloque la ventana emergente)
        const a = document.createElement("a")
        a.href = `https://videochat.mybeatcoach.com/sfu/${data.videocall_uuid}/${token}/null`
        a.target = "_blank"
        a.rel = "noopener"
        a.click()
      }

    return (
        <div className='containerScheduledSession' onClick={() => fifteenMinutesReaming() ? enterVideocall() : window.confirm(t('errorAccessCall'))}>

            <div className='containerRightScheduledSession'>
                <span className='titleScheduledSession'>Sesión con tu coach</span>
                <span className='dayScheduledSession'>{moment(data.start_date).format('dddd, DD MMMM')}</span>
                <span className='textScheduledSession'>{moment(data.start_date).format('HH:mm')} - {moment(data.end_date).format('HH:mm')}</span>
            </div>

            <div className='containerTextScheduledSession'>
              {fifteenMinutesReaming() ?
                <img className='iconScheduledSession' src={IconAssets.greenPhone}/>
                :
                <img className='iconScheduledSession' src={IconAssets.redPhone}/>
              }
              <span className='textScheduledSession'>{fifteenMinutesReaming() ? 'Accede a la llamada' : 'Accederás desde aqui a tu llamada'}</span>
            </div>

        </div>
    )
}

export default ScheduledSession