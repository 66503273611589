import axios from 'axios';
import {Const} from "../utils/variables";

const axiosInstance = axios.create({baseURL: `https://${Const.baseURL}/api/`, responseType: 'json'});


export const createThread = async () => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }
  return new Promise((resolve, reject) => {
    axiosInstance.get('create_thread', {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//Crea un mensaje del usuario en la IA
export const createMessage = async (message, thread_id) => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }

  const data = {
    thread_id: thread_id,
    message: message
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(`create_message`, data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//Hace que la IA procese el mensaje que ha enviado el usuario y genere una respuesta a el
export const runIa = async (thread_id, instructions) => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }

  const data = {
    thread_id: thread_id
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(`run_ia`, data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//Comprueba el estado del run
export const getRunState = async (thread_id, run_id) => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }

  const data = {
    thread_id: thread_id,
    run_id: run_id
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(`get_run_state`, data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

//Recoje los mensajes de la IA
export const getIaMessages = async (thread_id) => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }

  const data = {
    thread_id: thread_id
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(`get_ia_messages`, data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}

/*
* Lee todos los mensajes sin leer de la IA
**/
export const readAllIaMessages = async (channelId) => {
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('token')
  }

  const data = {
    channel_id: channelId
  }

  return new Promise((resolve, reject) => {
    axiosInstance.post(`read_all_ia_messages`, data, {headers}).then(res => {
      resolve(res);
    }).catch(err => {
      reject(err);
    })
  })
}
