// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
    width: 100%;
    height: 2.65vw;
    margin-top: 30px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: none;
    border-radius: 20px;
    background-color: #0F1841;
    font-weight: 600;
    font-size: 0.89vw;
    color: #FFFFFF;
    cursor: pointer;
}

button:disabled {
    background-color: rgba(15, 24, 65, 0.5);
}

.secondaryButton {
    width: 100%;
    height: 2.65vw;
    margin-top: 40px;
    border: 2px solid #0F1841;
    border-radius: 20px;
    background-color: transparent;
    font-weight: 600;
    font-size: 0.89vw;
    color: #0F1841;
    cursor: pointer;
}

.iconButton {
    width: 0.8vw;
    height: 0.75vw;
    margin-right: 0.525vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/button/button.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,0CAA0C;IAC1C,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,WAAW;IACX,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,6BAA6B;IAC7B,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".button {\n    width: 100%;\n    height: 2.65vw;\n    margin-top: 30px;\n    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);\n    border: none;\n    border-radius: 20px;\n    background-color: #0F1841;\n    font-weight: 600;\n    font-size: 0.89vw;\n    color: #FFFFFF;\n    cursor: pointer;\n}\n\nbutton:disabled {\n    background-color: rgba(15, 24, 65, 0.5);\n}\n\n.secondaryButton {\n    width: 100%;\n    height: 2.65vw;\n    margin-top: 40px;\n    border: 2px solid #0F1841;\n    border-radius: 20px;\n    background-color: transparent;\n    font-weight: 600;\n    font-size: 0.89vw;\n    color: #0F1841;\n    cursor: pointer;\n}\n\n.iconButton {\n    width: 0.8vw;\n    height: 0.75vw;\n    margin-right: 0.525vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
