import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {message} from 'antd';

import './rating.css';

import { Rate } from 'antd';

import {rateCoach} from '../../services/chat.service';
import {getSpecialities} from '../../services/data.service';

import routeStore from '../../store/routeStore';
import Button from '../../components/button/button.component';
import RadioButton from '../../components/radioButton/radioButton.component';

import { Const } from '../../utils/variables';

const Rating = () => {
    const location = useLocation();
    const navigate = useNavigate();


    const [rate, setRate] = useState(null);
    const [rateStatus, setRateStatus] = useState(null);
    const [coach, setCoach] = useState(location.state.coach);
    const [description, setDescription] = useState(location.state.coach);
    const [speciality, setSpeciality] = useState(null);
    const [allSpecialities, setAllSpecialities] = useState([]);
    const setRoute = routeStore(state => state.setRoute);


    useEffect(() => {
        // Guarda esta ruta para saber que hay una valoracion pendiente
        setRoute(location.pathname);
        setCoach(location.state.coach)
    }, [])

    // Envia al servidor la valoracion
    const ratingSession = () => {
        if (rate != null && rateStatus != null) {
            rateCoach(location.state.idChat, rate, rateStatus, description).then(res => {
                message.success('Valoración registrada correctamente.')
                setRoute(null)
                navigate('/')
            }).catch(err => {
                message.error('Ha habido un error enviando la valoración, vuelve ha intentarlo.')
            })
        } else {
            message.error('Completa los dos campos de valoración para poder valorar.')
        }
    }

    return (
        <div className={'containerRating'}>
            <p className={'titleRating'}>Valora a tu coach</p>

            <img className={'imageRating'} src={`https://${Const.baseURL}/`+coach.image}/>

            <Rate
                className={'rate'}
                allowHalf={false}
                defaultValue={0}
                onChange={value => setRate(value)}
            />


            <p className={'titleRating'}>¿Cuánto te ha ayudado esta conversación?</p>

            <Rate
                className={'rate'}
                allowHalf={false}
                defaultValue={0}
                onChange={value => setRateStatus(value)}
            />

            <p className={'titleRating'}>¿Cómo ha ido la conversación?</p>
            <textarea
                placeholder={'Cuentanos que opinas de tu conversación'}
                className={'textareaRating'}
                onChange={value => setDescription(value.target.value)}
            />

            <div className={'containerButtonRating'}>
                <Button
                    text={'Enviar'}
                    press={() => ratingSession()}
                />
            </div>
        </div>
    )
}

export default Rating;
