import {Routes, Route} from 'react-router-dom';

import Home from '../views/home/home'
import Messages from '../views/messages/messages';
import Coaches from '../views/coaches/coaches';
import Chats from '../views/chats/chats';
import Chat from '../views/chat/chat';
import ChatIa from '../views/chatIA/chatIa';
import Rating from '../views/rating/rating';
import Sessions from '../views/sessions/sessions'
import History from '../views/history/history';
import Profile from '../views/profile/profile';
import ChangePassword from '../views/auth/rememberPassword/changePassword';
import About from '../views/aboutMBC/about';
import Faq from '../views/faq/faq';

//import Coach from '../views/coach/coach';
//import ChatHistory from '../views/chatHistory/chatHistory';
//import ReportUser from '../views/reportUser/reportUser';
//import Diary from '../views/diary/diary';
//import Articles from '../views/articles/articles';
//import Article from '../views/article/article';

import NotFound from '../views/notFound/notFound';

// Router de las pantallas una vez autenticado
const Router = props => {

    return(
        <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/home'} element={<Home/>}/>
            <Route path={'/coaches'} element={<Coaches/>}/>
            <Route path={'/profile'} element={<Profile/>}/>
            <Route path={'/chat'} element={<Chat/>}/>
            <Route path={'/chati'} element={<ChatIa/>}/>
            <Route path={'/chats'} element={<Chats/>}/>
            <Route path={'/rating'} element={<Rating/>}/>
            <Route path={'/sessions'} element={<Sessions/>}/>
            <Route path={'/history'} element={<History/>}/>
            <Route path={'/changePassword'} element={<ChangePassword/>}/>
            <Route path={'/about'} element={<About/>}/>
            <Route path={'/faq'} element={<Faq/>}/>
            {/*<Route path={'/coach'} element={<Coach/>}/>*/}
            {/*<Route path={'/chatHistory'} element={<ChatHistory/>}/>*/}
            {/*<Route path={'/report'} element={<ReportUser/>}/>*/}
            {/*<Route path={'/diary'} element={<Diary/>}/>*/}
            {/*<Route path={'/articles'} element={<Articles/>}/>*/}
            {/*<Route path={'/article'} element={<Article/>}/>*/}

            <Route path={'*'} element={<NotFound/>}/>
        </Routes>
    )
}

export default Router;
