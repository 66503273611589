import React from 'react';

import './infoUserCard.css';
import {IconAssets, ImageAssets} from '../../utils/ImageAssets';

import moment from 'moment';

// Muestra el componente de sale al clicar dentro del nombre o imagen del usuario dentro de la conversacion
// En el que se muestran los datos relevantes del usuario
const InfoUserCard = ({user, close}) => {

    return (
        user &&
        <div className={'containerUserCard'}>
            <img className={'iconCloseUserCard'} src={IconAssets.close} onClick={() => close(false)}/>

            <div className={'containerImageUserCard'}>
                <img className={'imageUserCard'} src={user.channelUsers.user.image === null ? ImageAssets.userTest : `https://node.mybeatcoach.com/${user.channelUsers.user.image}`}/>
            </div>

            <p className={'nameUserCard'}>{user.channelUsers.user.name + '' + user.channelUsers.user.lastname}</p>
            <p className={'companyUserCard'}>Empresa {(user.channelUsers.user.Organizations && user.channelUsers.user.Organizations.length > 0) ? user.channelUsers.user.Organizations[0].name : 'Desconocida'}</p>

            <p className={'titleUserCard'}>Departamento asignado</p>
            <p className={'textDetailsUserCard'}>{(user.channelUsers.user.Departments && user.channelUsers.user.Departments.length > 0) ? user.channelUsers.user.Departments[0].name : 'Sin departamento'}</p>

            <p className={'titleUserCard'}>Email</p>
            <p className={'textDetailsUserCard'}>{user.channelUsers.user.email}</p>

            <p className={'titleUserCard'}>Sexo</p>
            <p className={'textDetailsUserCard'}>{user.channelUsers.user.gender}</p>

            <p className={'titleUserCard'}>Fecha de nacimiento</p>
            <p className={'textDetailsUserCard'}>{moment(user.channelUsers.user.birthday).format('DD/MM/yyyy')}</p>
        </div>
    )
}

export default InfoUserCard;
