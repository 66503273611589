// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerVerifyCode {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: 100vh;
    max-height: 100%;
}

.titleVerifyCode {
    font-size: 1.25vw;
    font-weight: bold;
}

.textVerifyCode {
    font-size: 1.10vw;
    font-weight: bold;
}

.containerInputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 12vw;
    margin-top: 40px;
}

.inputVerifyCode {
    width: 20%;
    height: 100%;
    font-size: 12vw;
    text-align: center;
    border-radius: 20px;
    outline: none;
}

.buttonVerifyCode {
    width: 30vw;
    margin-top: 40px;
}`, "",{"version":3,"sources":["webpack://./src/views/auth/verifyCode/verifyCode.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,UAAU;IACV,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".containerVerifyCode {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 90%;\n    height: 100vh;\n    max-height: 100%;\n}\n\n.titleVerifyCode {\n    font-size: 1.25vw;\n    font-weight: bold;\n}\n\n.textVerifyCode {\n    font-size: 1.10vw;\n    font-weight: bold;\n}\n\n.containerInputs {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 60%;\n    height: 12vw;\n    margin-top: 40px;\n}\n\n.inputVerifyCode {\n    width: 20%;\n    height: 100%;\n    font-size: 12vw;\n    text-align: center;\n    border-radius: 20px;\n    outline: none;\n}\n\n.buttonVerifyCode {\n    width: 30vw;\n    margin-top: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
