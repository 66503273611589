import React, {useState, useEffect} from 'react';

import './stateUser.css';

// Muestra el componente del select de los posibles estados del coach
const StateUser = ({value, changeValue}) => {
    const [dot, setDot] = useState(value);

    return (
        <div className={'containerStateUser'}>
            <p className={'textStateUser'}>Estado</p>

            <div className={'containerSelectStateUser'}>
                <div className={(value === 'Disponible' || value == 'Online') ? 'dotGreen' : (value === 'Busy') ? 'dotOrange' : 'dotRed'}></div>

                <select
                    className={'selectStateUser'}
                    onChange={value => {
                        setDot(value.target.value)
                        changeValue(value.target.value)
                    }}
                >
                    <option selected={value === 'Disponible'}>Disponible</option>
                    <option selected={value === 'Busy'}>Ausente</option>
                    <option selected={value !== 'Disponible' && value !== 'Busy'}>No disponible</option>
                </select>
            </div>
        </div>
    )
}

export default StateUser;
