// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerScheduledSession {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    padding: 0.625vw 2vw;
    border: 1px solid hsl(229, 62%, 15%);
    border-radius: 15px;
    background-color: #dbdbdb;
}

.containerRightScheduledSession {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.titleScheduledSession {
    font-weight: bold;
    font-size: 1vw;
}

.dayScheduledSession {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 1vw;
}

.containerTextScheduledSession {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iconScheduledSession {
    width: 1.56vw;
}

.textScheduledSession {
    font-size: 1vw;
    margin-top: 5px;

}`, "",{"version":3,"sources":["webpack://./src/components/scheduledSession/scheduledSession.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,UAAU;IACV,oBAAoB;IACpB,oCAAoC;IACpC,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;;AAEnB","sourcesContent":[".containerScheduledSession {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    width: 95%;\n    padding: 0.625vw 2vw;\n    border: 1px solid hsl(229, 62%, 15%);\n    border-radius: 15px;\n    background-color: #dbdbdb;\n}\n\n.containerRightScheduledSession {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.titleScheduledSession {\n    font-weight: bold;\n    font-size: 1vw;\n}\n\n.dayScheduledSession {\n    margin-top: 10px;\n    margin-bottom: 10px;\n    font-size: 1vw;\n}\n\n.containerTextScheduledSession {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.iconScheduledSession {\n    width: 1.56vw;\n}\n\n.textScheduledSession {\n    font-size: 1vw;\n    margin-top: 5px;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
