// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerSpecialityBig {
    width: 21%;
    padding: 7px 10px;
    border-radius: 20px;
    border: none;
    background-color: #0f1840;
    color: #FFFFFF;
    text-align: left;
}

.titleSpecialityBig {
    margin: 5px;
    font-size: 1.2vw;
    font-weight: bold;
}

.descriptionSpecialityBig {
    margin: 5px;
    font-size: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/components/specialityBig/specialityBig.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,cAAc;AAClB","sourcesContent":[".containerSpecialityBig {\n    width: 21%;\n    padding: 7px 10px;\n    border-radius: 20px;\n    border: none;\n    background-color: #0f1840;\n    color: #FFFFFF;\n    text-align: left;\n}\n\n.titleSpecialityBig {\n    margin: 5px;\n    font-size: 1.2vw;\n    font-weight: bold;\n}\n\n.descriptionSpecialityBig {\n    margin: 5px;\n    font-size: 1vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
