import React from 'react';
import './faq.css'

import SpecialityBig from '../../components/specialityBig/specialityBig.component';
import {useTranslation} from "react-i18next";

const Faq = () => {

    const {t} = useTranslation();

    const faqs = [
        {
            title: t('question1'),
            text: t('response1')
        },
        {
            title: t('question2'),
            text: t('response2')
        },
        {
            title: t('question3'),
            text: t('response3')
        },
        {
            title: t('question4'),
            text: t('response4')
        },
        {
            title: t('question5'),
            text: t('response5')
        },
        {
            title: t('question6'),
            text: t('response6')
        },
        {
            title: t('question7'),
            text: t('response7')
        },
    ]

    return (
        <div className='containerFaq'>
            <p className='titleFaq'>{t('faq')}</p>

            <div className='containerItemsFaq'>
                {faqs.map(faq => {
                    return (
                        <SpecialityBig
                            title={faq.title}
                            description={faq.text}
                        />
                    )
                })
                }
            </div>
        </div>
    )
}

export default Faq;