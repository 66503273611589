// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerScheduleComponent {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625vw;
    border: 1px solid #0f1840;
    border-radius: 15px;
    width: 100%;
    background-color: #dbdbdb;
}

.cubeSchedule {
    width: 3.64vw;
    height: 3.54vw;
    margin-right: 2.60vw;
    border-radius: 15px;
    background-color: #0f1840;
}

.textSchedule {
    font-size: 1.2vw;
}`, "",{"version":3,"sources":["webpack://./src/components/scheduleSession/scheduleSession.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,gBAAgB;IAChB,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,cAAc;IACd,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".containerScheduleComponent {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0.625vw;\n    border: 1px solid #0f1840;\n    border-radius: 15px;\n    width: 100%;\n    background-color: #dbdbdb;\n}\n\n.cubeSchedule {\n    width: 3.64vw;\n    height: 3.54vw;\n    margin-right: 2.60vw;\n    border-radius: 15px;\n    background-color: #0f1840;\n}\n\n.textSchedule {\n    font-size: 1.2vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
