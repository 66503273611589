import React from 'react'
import './scheduleSession.css'


const ScheduleComponent = ({press}) => {

    return (
        <div className='containerScheduleComponent' onClick={press}>
            <div className='cubeSchedule'>

            </div>

            <span className='textSchedule'>Agenda tu próxima sesión con tu Coach</span>

        </div>
    )
}

export default ScheduleComponent