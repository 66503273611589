import React, {useEffect, useState} from 'react';
import './about.css'

import { getSpecialities } from '../../services/data.service';

import SpecialityBig from '../../components/specialityBig/specialityBig.component';
import {useTranslation} from "react-i18next";

const About = () => {
    const [specialities, setSpecialities] = useState([])

    const {t} = useTranslation();

    useEffect(() => {
        getSpecialities().then(res => {
            setSpecialities(res.data);
        }).catch(err => {
            console.log('Error recogiendo spct')
        })
    }, [])

    return (
        <div className='containerAbout'>
            <p className='titleAbout'>{t('aboutMbc')}</p>

            <p className='textAbout'>{t('textAbout')}</p>
        
            <p className='titleAbout'>{t('discoverSpecialities')}</p>

            <div className={'containerSpecialitiesAbout'}>
                {specialities.map(speciality => {
                    return (
                        <SpecialityBig
                            title={speciality.name}
                            description={speciality.description}
                        />
                    )
                })
                }

            </div>
        </div>
    )
}

export default About;