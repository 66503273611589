import React from 'react';
import './specialityBig.css'

const SpecialityBig = ({title, description}) => {

    return (
        <div className='containerSpecialityBig'>
            <p className='titleSpecialityBig'>{title}</p>
            <hr style={{borderColor: '#FFFFFF',}}/>
            <p className='descriptionSpecialityBig'>{description}</p>
        </div>
    )
}

export default SpecialityBig;