import React, {useEffect, useState} from 'react';

import './history.css';

import {useNavigate} from 'react-router-dom';

import Searcher from '../../components/searcher/searcher.component';
import HistoryItem from '../../components/historyItem/historyItem.component';

import {getCloseConversations} from '../../services/chat.service';
import {useTranslation} from "react-i18next";

const History = () => {
    const [allChats, setAllChats] = useState([]);
    const [chats, setChats] = useState([]);
    const navigate = useNavigate();

    const {t} = useTranslation();

    // Al entrar a la vista recoge todos lo chats finalizados del coach
    useEffect(() => {
        getCloseConversations(0).then(res => {
            setChats(res.data);
            setAllChats(res.data)
        }).catch(err => {
            //console.log('Error al recoger los chats cerrados')
        })
    }, [])

    const filterCoaches = (value) => {
        if (value !== '') {
            let newChats = chats.filter(chat => {
                if (chat != null) {
                    let fullName = chat.channelUsers.coach.name + ' ' + chat.channelUsers.coach.lastname
                    if (fullName.toLowerCase().startsWith(value.toLowerCase())) return chat;
                }
            })
            setChats(newChats);
        }
        else {
            setChats(allChats);
        }
      }

    return (
        <div className={'containerHistory'}>
            <p className='titleHistory'>{t('history')}</p>

            <Searcher  changeValue={value => filterCoaches(value)}/>

            <div className={'containerItemsHistory'}>
                {chats.map(chat => {
                    if (chat != null) {
                        return (
                            <HistoryItem
                                image={chat.channelUsers.coach.image}
                                name={chat.channelUsers.coach.name + ' ' + chat.channelUsers.coach.lastname}
                                //company={(chat.User.Organizations && chat.User.Organizations.length > 0) ? chat.User.Organizations[0].name : 'Desconocidad'}
                                date={chat.ended_at}
                                press={() => navigate('/chat', {state: {idChat: chat.id, coach: chat.channelUsers.coach, writer: false}})}
                            />
                        )
                    }
                    
                })
                }
            </div>
        </div>
    )
}

export default History;
