import React, {useState, useRef, useEffect} from 'react'
import './home.css'
import {useNavigate} from 'react-router-dom';

import ScheduleComponent from '../../components/scheduleSession/scheduleSession.component';
import ScheduledSession from '../../components/scheduledSession/scheduledSession.component';
import ChatItem from '../../components/chatItem/chatItem.component';

import { getActiveTrendingTopics, getScheduleSession, getChannelsWithUnreadMessages, getIaUser } from '../../services/data.service';
import { createThread } from '../../services/ia.service';

import { IconAssets } from '../../utils/ImageAssets';
import * as moment from 'moment';
import {useTranslation} from "react-i18next";

const Home = () => {
    const [user, setUser] = useState()
    const [showIframe, setShowIframe] = useState(false)
    const [sessions, setSessions] = useState([])
    const [chats, setChats] = useState([])
    const [coachingPreferences, setCoachingPreferences] = useState([])
    const [trendingTopics, setTrendingTopics] = useState([])
    let iframeRef = useRef()
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        //Eliminamos el id del chat que acabamos de crear
        localStorage.removeItem('idNewChat')

        setUser(JSON.parse(localStorage.getItem('USER')).user)

        console.log('el user', JSON.parse(localStorage.getItem('USER')).user)

        let user = JSON.parse(localStorage.getItem('USER')).user


        getTrendingTopics()

        setCoachingPreferences(user.coaching_preferences ? user.coaching_preferences.split('#') : [])

        getActiveSession(user.id)

        getChats(user.id)
    }, [])

    const getActiveSession = (userId) => {
        getScheduleSession(userId).then(res => {
            console.log('estas son las sesione', res.data)
            setSessions(res.data)
        }).catch(err => {
            console.log('error get session', err)
        })
    }

    const getChats = (userId) => {
        getChannelsWithUnreadMessages(userId).then(res => {
          setChats(res.data)
          console.log('estos son los mensajes sin leer', res.data)
          //getLastMessages(res.data)
        }).catch(err => {
          console.log('ERROR recogiendo los chats', err)
        })
      }

      const getTrendingTopics = () => {
        getActiveTrendingTopics().then(res => {
            console.log('estos son los tending topics', res.data)
            setTrendingTopics(res.data)
        }).catch(err => {
            console.log('error get trending topics', err)
        })
      }

      const newIaChat = (speciality) => {
        if (window.confirm(t('newChatText'))) {
            getIaUser().then(res => {
                let coach = res.data[0]
                //Crea un nuevo hilo IA
                createThread().then(res => {
                    navigate('/chati', {state: {isNew: true, speciality: speciality, thread_id: res.data.id, coach: coach, writer: true}})
                    //Crea un chat o recoge el existente
                    /*createIAChat(coach.id, res.data.id, navigator.language, speciality).then(newChat => {
                        //Si el chat ya tiene el id del hilo navega a la vista mandado el id del hilo que contiene el chat
                        //Si el chat es nuevo navega a la vista y envia el id del hilo que acabamos de crear
                        if (newChat.data.thread_id != null) {
                            console.log('new chat')
                            navigate('/chati', {state: {idChat: newChat.data.id, thread_id: newChat.data.thread_id, coach: coach, writer: true}})
                        } else {
                            console.log('new chat')
                            navigate('/chati', {state: {idChat: newChat.data.id, thread_id: res.data.id, coach: coach, writer: true}})
                        }
                    }).catch(err => {
                        console.log('No se ha podido crear el nuevo chat', err)
                    })*/
                }).catch(err => {
                console.log('Error creating thread', err)
                })
            
            })
            } else {
                console.log('No iniciar nuevo chat')
            }
          
      }

      

    return (
        showIframe ?
        <div style={{width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', height: 70, backgroundColor: '#0f1840'}}>
                <img style={{marginLeft: 20}} src={IconAssets.back} onClick={() => {
                    setShowIframe(false)
                    getActiveSession(user.id)
                    }}/>
            </div>
        <iframe
            id={'iframe'}
            ref={ref => iframeRef = ref}
            style={{width: '100%', height: '100vh'}}
            src={`https://calendly.com/mybeatcoach-info/30min?primary_color=d21217%22&month=2024-08&user_id=${user.id}&email=${user.email}`}
            onLoad={() => {
            }}
        >
        </iframe>
        </div>
        :
        <div className={'containerHome'}>
        <div style={{width: '98%'}}>


        {(trendingTopics.length > 0 || coachingPreferences.length > 0) &&

            <p className='titleHome'>Desarrollo</p>

        }

        <div className='containerAllPreferences'>
        {coachingPreferences.length > 0 &&
            <div  className='containerPreferences'>
                <span className='subtitleHome'>{t('yourGoals')}</span>
                {coachingPreferences.map(preference => {
                    return (
                        <div className='containerPreferenceHome'>
                            <span className='preferenceTextHome'>{preference}</span>
                            <div className='containerIconsPreferencesHome'>
                                <img 
                                    style={{width: 20}} 
                                    src={IconAssets.chatBlack}
                                    onClick={() => {
                                        newIaChat(preference)
                                    }}
                                />
                                <img 
                                style={{width: 25}} src={IconAssets.blackCam}
                                onClick={() => {
                                    if (sessions.length == 0) {
                                        setShowIframe(true)
                                    } else {
                                        window.confirm(t('notCanScheduleSession'))
                                    }
                                }}
                                />
                            </div>
                        </div>
                    )
                })

                }
            </div>
        }

        {trendingTopics.length > 0 &&
            <div className='containerPreferences'>
                <span className='subtitleHome'>Trending Topics</span>
                {trendingTopics.map(topic => {
                    return (
                        <div className='containerPreferenceHome'>
                            <span className='preferenceTextHome'>{topic.title}</span>
                            <div className='containerIconsPreferencesHome'>
                                <img 
                                    style={{width: 20}} 
                                    src={IconAssets.chatBlack}
                                    onClick={() => newIaChat(topic.title)}
                                />
                                <img 
                                style={{width: 25}} src={IconAssets.blackCam}
                                onClick={() => {
                                    if (sessions.length == 0) {
                                        setShowIframe(true)
                                    } else {
                                        window.confirm(t('notCanScheduleSession'))
                                    }
                                }}
                                />
                            </div>
                        </div>
                    )
                })

                }
            </div>
        }
        </div>






            <p className='titleHome'>{t('newChatMessages')}</p>
            {
                chats.length > 0 ?
                    chats.map((chat, index) => {
                        console.log('chatt', chat)
                    {if (chat != null && chat.Messages.length > 0) {
                        return(
                            <ChatItem
                            image={chat.User.image}
                            fullName={chat.User.name + ' ' + chat.User.lastname}
                            status={chat.User.status}
                            //timeConnect={chat.Messages && chat.Messages.length > 0 && chats.length == lastMessages.length ? lastMessages[index].message : ''}
                            //quantitySpecialities={7}
                            idCurrentUser={user.id}
                            //idUserLastMessage={lastMessages[index] ? lastMessages[index].userId : null}
                            //lastMessageIsRead={lastMessages[index] ? lastMessages[index].read : null}
                            newMessages={chat.new_messages}
                            coachIsIa={chat.User.is_ia}
                            press={() => {
                              if (chat.User.is_ia) {
                                navigate('/chati', {state: {isNew: false, idChat: chat.id, thread_id: chat.thread_id, coach: chat.User, writer: true}})
                              } else {
                                navigate('/chat', {state: {isNew: false, idChat: chat.id, coach: chat.User, writer: true}})
                              }
                            }}
                            timeLastMessage={chat.Messages.length > 0 ? chat.Messages[0].createdAt : ''}
                        />
                        )
                    }}
                    })
                    :
                    <div className='buttonNoChats' onClick={() => navigate('/coaches')}>
                        {t('initInmediatlyChat')}
                    </div>
            }


            <div style={{marginBottom: 50}}>
                <p className='titleHome'>{t('text1Sessions')}</p>
                {sessions.length > 0 ?
                    <ScheduledSession data={sessions[0]}/>
                    :
                    <ScheduleComponent press={() => setShowIframe(true)}/>
                }
            </div>
            
        </div>
        </div>
    )
}

export default Home